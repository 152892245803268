import React from "react";
import { Ind } from "../index";

const IndependenceDay = () => {
  return (
    <div>
      <Ind />
    </div>
  );
};

export default IndependenceDay;
