import React from "react";


const Certificate = () => {
  return (
    <div className="">
      <img className="m-auto w-full" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/certificate.jpg"} alt="certificate" />
    </div>
  );
};

export default Certificate;
