import React from "react";
import { Journey } from "../index";

const Ourjourney = () => {
  return (
    <React.Fragment>
      <Journey />
    </React.Fragment>
  );
};

export default Ourjourney;
