import React from "react";
import { Design } from "../index";

const DesignersRetreat = () => {
  return (
    <div>
      <Design />
    </div>
  );
};

export default DesignersRetreat;
