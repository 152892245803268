import React from "react";
import { ContactUs, ContactForm } from "../index";

const GetInTouch = () => {
  return (
    <main>
      <ContactUs />
      <ContactForm />
    </main>
  );
};

export default GetInTouch;
