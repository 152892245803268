import React from "react";
import { CSR } from "../index";
import CSRVideo from "../CSRVideo";
import CSRMission from "../CSRMission";

const CooperateSocial = () => {
  return (
    <React.Fragment>
      <CSR />
      <CSRVideo />
      <CSRMission />
    </React.Fragment>
  );
};

export default CooperateSocial;
