import React from "react";
import Flickity from "react-flickity-component";


import "../styles/flickity.css";

const flickityOptions = {
  initialIndex: 0,
  groupCells: false,
  autoPlay: true,
  pageDots: false,
  prevNextButtons: true,
  draggable: true,
  pauseAutoPlayOnHover: false,
  wrapAround: true,
};

const HomeSlider = () => {
  return (
    <Flickity
      className={"carousel mb-10"}
      elementType={"div"}
      options={flickityOptions}
      disableImagesLoaded={false}
      reloadOnUpdate
      static
    >
      <div className="event-carousell">
        <img className="flic-img web" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/s4.jpg"} alt="Events1" />
        <img className="flic-img mob" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/ms1.png"} alt="Events1" />
      </div>
      <div className="event-carousell">
        <img className="flic-img web" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/s1.jpg"} alt="Events2" />
        <img className="flic-img mob" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/ms2.jpg"} alt="Events2" />
      </div>
      <div className="event-carousell">
        <img className="flic-img web" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/s2.jpg"} alt="Events3" />
        <img className="flic-img mob" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/ms3.jpg"} alt="Events3" />
      </div>
      <div className="event-carousell">
        <img className="flic-img web" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/s3.jpg"} alt="Events4" />
        <img className="flic-img mob" src={"https://cdn.shopify.com/s/files/1/0845/6591/8016/files/ms4.jpg"} alt="Events4" />
      </div>
    </Flickity>
  );
};

export default HomeSlider;
