import React from "react";
import { Women } from "../index";

const WomensDay = () => {
  return (
    <div>
      <Women />
    </div>
  );
};

export default WomensDay;
