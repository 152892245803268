import React from "react";
import "../styles/Finance.css";


const FinancialReports = () => {
  return (
    <React.Fragment>
      <div className="md:grid lg:grid-cols-3 md:grid-cols-2">
        
        {/* @1 */}
        <div className="xs:text-center mb-6 lg:text-left">
          <h5 className="text-[#0284c7] my-2">Annual Reports:</h5>
          <ul className="text-sm wd">
          <li className="py-1 df">
              Annual Report 2024
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href={'https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Annual-Report-2024.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
          <li className="py-1 df">
              Annual Report 2023
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href={'https://cdn.shopify.com/s/files/1/0845/6591/8016/files/Annual-Report-2023.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Annual Report 2022
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Annual-Report-2022_compressed.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Annual Report 2021
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Annual-Report-2021.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Annual Report 2020
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Annual-Report-2020-TRPOL.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Annual Report 2019
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Annual-Report-2019.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
          </ul>
        </div>
        {/* -------------------------------------------------------------- */}
        {/* @2 */}
        <div className="xs:text-center mb-6 lg:text-left">
          <h5 className="text-[#0284c7] my-2">Half Yearly Reports:</h5>
          <ul className="text-sm wd">
          <li className="py-1 df">
              Half Yearly 2023
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0845/6591/8016/files/half_yearly_report_23.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Half Yearly 2022
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Half-Yearly-Report-December-2022-Final.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Half Yearly 2020
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Half_Year_2020.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Half Yearly 2019
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Half_Year_2019.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Half Yearly 2018
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/Half_Year_2018.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
          </ul>
        </div>
        {/* -------------------------------------------------------------- */}
        {/* @3 */}
        <div className="xs:text-center mb-6 lg:text-left">
          <h5 className="text-[#0284c7] my-2">Quarterly Reports:</h5>
          <ul className="text-sm wd">
            <li className="py-1 df">
              First Quarter 2020
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/FQ20.pdf?v=1683549557"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Third Quarter 2019
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/TQ19.pdf?v=1683549281"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              First Quarter 2019
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/FQ19.pdf?v=1683549625"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              Third Quarter 2018
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/TQ18.pdf?v=1683549368"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            <li className="py-1 df">
              First Quarter 2018
              <a
                className="text-black  text-sm hover:underline font-semibold"
                href="https://cdn.shopify.com/s/files/1/0689/7349/4550/files/FQ18.pdf?v=1683549692"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>
            </li>
            {/* --------------------------------- */}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinancialReports;
